import React, { useContext, useState } from "react";
import { Badge, Box, Chip, IconButton, InputAdornment, TextField } from "@mui/material";
import bkstApi from "../../api";
import CancelIcon from "@mui/icons-material/Cancel";
import SendIcon from "@mui/icons-material/Send";
import ConfigContext from "../../context/ConfigContext";
import CloudinaryUploadWidget from "../CloudinaryUploadWidget";
import AttachFileIcon from "@mui/icons-material/AttachFile";

export default function NewMessage(props) {
  const siteConfig = useContext(ConfigContext);

  const [msg, setMsg] = useState("");
  const [pics, setPics] = useState([]);

  const [lineBreaks, setLineBreaks] = useState(1);

  const [processing, setProcessing] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!processing) {
      setProcessing(true);
      const payload = { msg, pics, ts: Date.now(), uid: "" };

      bkstApi.post(`/site/${siteConfig.id}${props.postUrl}`, payload).then((res) => {
        props.handleNewMsg({ ...payload, by: "cust" });
        setMsg("");
        setPics([]);
        setLineBreaks(1);
        setProcessing(false);
      });
    }
    e.target && e.target.blur();
  };

  const onEnter = (e) => {
    if (e.key === "Enter" && msg.trim().length > 0) {
      const tmp = lineBreaks + 1;
      setLineBreaks(tmp);
    }
  };

  const removePhoto = (idx) => {
    let arr = [...pics];
    arr.splice(idx, 1);
    setPics(arr);
  };

  let rows = Math.round(msg.length / 40) + +lineBreaks;
  rows = rows > 6 ? 6 : rows;

  return (
    <Box px={1} py={1}>
      <form onSubmit={handleSubmit}>
        <Box display="flex">
          <CloudinaryUploadWidget
            folder={`s/${siteConfig.id}/item`}
            onComplete={(arr) => {
              setPics(arr);
            }}
          >
            <IconButton>
              <AttachFileIcon />
            </IconButton>
          </CloudinaryUploadWidget>
          <TextField
            id="msg"
            value={msg}
            name="msg"
            variant="outlined"
            multiline
            rows={rows}
            fullWidth
            placeholder="Type a message"
            onChange={(e) => setMsg(e.target.value)}
            onKeyDown={onEnter}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton color="primary" type="submit" variant="contained" disabled={msg.length === 0 && pics.length === 0}>
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                paddingRight: 0
              },
              backgroundColor: "#FFFFFF"
            }}
          />
        </Box>
      </form>
      {pics && pics.length > 0 && (
        <Box align="center" style={{ display: "flex", gap: "20px", paddingLeft: "40px", paddingTop: "15px" }}>
          {pics.map((pic, idx) => {
            return (
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                badgeContent={
                  <IconButton onClick={() => removePhoto(idx)} size="small">
                    <CancelIcon />
                  </IconButton>
                }
              >
                <img key={idx} src={pic} style={{ height: "40px", width: "40px", objectFit: "cover", border: "1px solid #ECECEC" }} />
              </Badge>
            );
          })}
        </Box>
      )}
      {props.templates && (
        <Box style={{ marginLeft: "30px" }}>
          {props.templates.map((t) => (
            <Chip key={t.name} size="small" label={t.name} component="a" onClick={() => setMsg(t.text)} clickable />
          ))}{" "}
        </Box>
      )}
    </Box>
  );
}
