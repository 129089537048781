import { useState, useEffect } from "react";
import { Box, Button, FormControl, FormControlLabel, Grid, Paper, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import Images from "../../common/component/image/Images";

export default function CakeDetails(props) {
  const [showDietary, setShowDietary] = useState(false);
  const [tweak, setTweak] = useState("");
  const [details, setDetails] = useState("");
  const [dietary, setDietary] = useState("");
  const [pics, setPics] = useState(props.pics);

  useEffect(() => {
    if (tweak === "no") {
      setDetails("");
    }
  }, [tweak]);

  const onSubmit = (e) => {
    e.preventDefault();
    props.onSubmit({ dietary, details: `${dietary} ${details}`, pics });
  };

  return (
    <Paper>
      <Box px={2} py={7}>
        <Grid container>
          <Grid item sm={2} xs={12}></Grid>
          <Grid item sm={8} xs={12}>
            <Box mb={5} textAlign="center">
              <Typography variant="h4" gutterBottom>
                Customize your Order
              </Typography>
              <Typography variant="body1" color="textSecondary">
                This will be a custom-made order, so you can change pretty much anything
              </Typography>
            </Box>
            <form onSubmit={onSubmit}>
              <Grid container spacing={2} alignItems="center">
                <Grid item sm={2} xs={3}>
                  <Images pics={props.pics} edit={true} onChange={(images) => setPics(images)} folder={`img/r`} limit={5} />
                </Grid>
                <Grid item sm={10} xs={9}>
                  <RadioGroup name="tweak" value={tweak} onChange={(e) => setTweak(e.target.value)}>
                    <Box my={2}>
                      <FormControlLabel value="no" control={<Radio required={true} />} label={`No Customization`} />
                      <Box>
                        <Typography variant="body2" color="textSecondary">
                          We'll match the inspiration design to the best of our abilities
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <FormControlLabel value="yes" control={<Radio required={true} />} label={`Customize this design`} />
                      <Box>
                        <Typography variant="body2" color="textSecondary">
                          We'll change the inspiration design based on your instructions
                        </Typography>
                      </Box>
                    </Box>
                  </RadioGroup>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    {tweak === "yes" && (
                      <Box mt={2}>
                        <FormControl variant="outlined" fullWidth>
                          <TextField
                            autoFocus={tweak === "yes"}
                            value={details}
                            variant="outlined"
                            fullWidth
                            id="details"
                            label=""
                            name="details"
                            multiline
                            rows={3}
                            onChange={(e) => setDetails(e.target.value)}
                            required
                            placeholder="Change tiers, add/remove toppers, decals, toys, figurines, flowers, etc."
                          />
                        </FormControl>
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  {!showDietary && (
                    <Button variant="text" onClick={() => setShowDietary(true)} fullWidth>
                      Add dietary choices
                    </Button>
                  )}
                  {showDietary && (
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        value={dietary}
                        variant="outlined"
                        id="dietary"
                        label="Dietary choices"
                        name="dietary"
                        fullWidth
                        onChange={(e) => setDietary(e.target.value)}
                        helperText="Nut-free, eggless, vegan, etc"
                      />
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Button size="large" color="primary" variant="contained" type="submit" fullWidth>
                    Continue
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
