import { useEffect, useRef } from "react";
import { useTheme } from "@mui/material/styles";

export default function UploadWidget(props) {
  const theme = useTheme();

  const cloudinaryRef = useRef();
  const widgetRef = useRef();

  const MAX_FILES = 5;

  useEffect(() => {
    cloudinaryRef.current = window.cloudinary;
    widgetRef.current = cloudinaryRef.current.createUploadWidget(
      {
        cloudName: "draqf7k7f",
        uploadPreset: "saas-upload",
        folder: props.folder,
        sources: ["local", "camera"],
        max_files: MAX_FILES,
        clientAllowedFormats: ["image"],
        maxFileSize: 10000000, // 10MB
        showPoweredBy: false,
        //autoMinimize: true,
        singleUploadAutoClose: true,
        showUploadMoreButton: false,
        language: "en",
        text: {
          en: {
            local: {
              dd_title_multi: "Drag & Drop Photos"
              //browse: "SELECT PHOTOS"
            },
            notifications: {
              limit_reached: `Can't upload more than ${MAX_FILES} photos`
            },
            menu: {
              files: "Device Files"
            }
          }
        },
        styles: {
          palette: {
            window: "#ffffff",
            sourceBg: "#f4f4f5",
            windowBorder: "#90a0b3",
            tabIcon: "#000000",
            inactiveTabIcon: "#555a5f",
            menuIcons: "#555a5f",
            link: theme.palette.primary.main,
            action: theme.palette.primary.main,
            inProgress: "#0433ff",
            complete: theme.palette.primary.main,
            error: "#F44235",
            textDark: "#000000",
            textLight: "#fcfffd"
          },
          fonts: {
            default: null,
            "sans-serif": {
              url: null,
              active: true
            }
          }
        }
      },
      function (error, result) {
        console.log(result, error);
        // Handle the result or error here
        if (result.event === "queues-end") {
          props.onComplete(result.info.files?.map((o) => o.uploadInfo.secure_url));
        }
      }
    );
  }, []);

  return <span onClick={() => widgetRef.current.open()}>{props.children}</span>;
}
