import React, { useContext, useState } from "react";
import { Backdrop, Badge, Box, Button, CircularProgress, Dialog, DialogContent, Grid, IconButton, Link, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { upload } from "../../firebase";
import { STORAGE_BUCKET, IMG_PLACEHOLDER } from "../../constants";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CloudinaryUploadWidget from "../CloudinaryUploadWidget";
import ConfigContext from "../../context/ConfigContext";

const navBtnStyle = {
  position: "absolute",
  zIndex: 2,
  top: "50%",
  transform: "translateY(-50%)",
  color: "#000",
  backgroundColor: "#fff",
  borderColor: "#ebebeb",
  opacity: ".7",
  padding: "0"
};

const navBtnPrevStyle = Object.assign({ left: "8px" }, navBtnStyle);
const navBtnNextStyle = Object.assign({ right: "8px" }, navBtnStyle);

export default function Images(props) {
  const siteConfig = useContext(ConfigContext);

  const [pics, setPics] = useState(props.pics || []);
  const [selectedIdx, setSelectedIdx] = useState(0);
  const [open, setOpen] = useState(false);
  const [working, setWorking] = useState(false);

  let style = props.style || { width: "100%", aspectRatio: "1", objectFit: "cover" };

  const close = () => {
    setSelectedIdx(0);
    setOpen(false);
  };

  const handleUpload = async (event, folder) => {
    if (props.limit) {
      const total = pics.length + event.target.files.length;
      if (total > props.limit) {
        window.alert(`Can't upload more than ${props.limit} photos`);
        return;
      }
    }
    let tasks = [];
    setWorking(true);

    for (var i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];

      // disable heic and heif
      if (file.name.endsWith(".heic") || file.name.endsWith(".heif")) {
        setWorking(false);
        window.alert(`Unsupported file format. Please upload jpeg or png file.`);
        return;
      }

      tasks.push(
        new Promise(async (resolve, reject) => {
          upload(file, folder).then((snapshot) => {
            snapshot.ref.getDownloadURL().then(function (downloadURL) {
              resolve(downloadURL);
            });
          });
        })
      );
    }

    const imageUrls = await Promise.all(tasks);
    const arr = pics.concat(imageUrls);

    setPics(arr);
    props.onChange(arr);

    setWorking(false);
  };

  const remove = (idx) => {
    let arr = [...pics];
    arr.splice(idx, 1);
    setPics(arr);

    props.onChange(arr);
    //close();
  };

  const inputComponent = (
    <CloudinaryUploadWidget
      folder={`s/${siteConfig.id}/item`}
      onComplete={(newPics) => {
        const arr = pics.concat(newPics);
        setPics(arr);
        props.onChange(arr);
      }}
    >
      <Button variant="contained">ADD PHOTO</Button>
    </CloudinaryUploadWidget>
  );

  const backdrop = (
    <Backdrop open={working} style={{ zIndex: "1000", color: "#fff" }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );

  let placeholderImg = `${STORAGE_BUCKET}${IMG_PLACEHOLDER}`;

  const uploadPlaceholder = (
    <Link component="label" style={{ textAlign: "center", cursor: "pointer" }} underline="hover">
      <Box>
        {backdrop}
        <img src={placeholderImg} style={style} />
        {inputComponent}
      </Box>
    </Link>
  );

  let header = (
    <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
      <IconButton color="inherit" onClick={close}>
        <ArrowBackIosNewIcon />
      </IconButton>
      <Box sx={{ flexGrow: "1", textAlign: "center", display: "flex", gap: "10px", justifyContent: "center", alignItems: "center" }}>
        <Typography variant="overline">{`${pics.length} photos`}</Typography>
        {props.edit && inputComponent}
      </Box>
      <IconButton color="inherit" onClick={close}>
        <CloseIcon />
      </IconButton>
    </Box>
  );

  return (
    <Badge badgeContent={pics.length > 1 ? pics.length : 0} color="info">
      <Box id="images" style={{ cursor: "pointer", backgroundColor: "#ECECEC", borderRadius: "3px" }} align="center">
        {props.edit && pics.length === 0 ? (
          uploadPlaceholder
        ) : (
          <Box>
            <Carousel
              interval={5000}
              transitionTime={1000}
              showArrows={props.showArrows || false}
              showIndicators={false}
              showStatus={false}
              showThumbs={false}
              onClickItem={() => setOpen(true)}
              renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                  <IconButton size="small" onClick={onClickHandler} title={label} style={navBtnPrevStyle}>
                    <ChevronLeftIcon />
                  </IconButton>
                )
              }
              renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                  <IconButton size="small" onClick={onClickHandler} title={label} style={navBtnNextStyle}>
                    <ChevronRightIcon />
                  </IconButton>
                )
              }
            >
              {(pics && pics.length > 0 ? pics : [placeholderImg]).map((pic, idx) => {
                return <img alt="" src={pic} key={idx} style={style} />;
              })}
            </Carousel>
            {props.edit && (
              <Typography
                variant="caption"
                display="block"
                sx={{ lineHeight: "1", paddingTop: "7px", paddingBottom: "4px" }}
                onClick={() => setOpen(true)}
              >
                {pics.length} PHOTOS
              </Typography>
            )}
          </Box>
        )}
        <Dialog fullScreen open={open} onClose={close}>
          <DialogContent style={{ backgroundColor: "#000", color: "#fff", alignItems: "center" }}>
            {header}
            {backdrop}
            <Box mt={2} align="center">
              <Carousel
                selectedItem={selectedIdx}
                transitionTime={1000}
                showIndicators={false}
                showStatus={false}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                  hasPrev && (
                    <IconButton onClick={onClickHandler} title={label} style={navBtnPrevStyle} size="large">
                      <ChevronLeftIcon />
                    </IconButton>
                  )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                  hasNext && (
                    <IconButton onClick={onClickHandler} title={label} style={navBtnNextStyle} size="large">
                      <ChevronRightIcon />
                    </IconButton>
                  )
                }
              >
                {pics.map((pic, idx) => {
                  return (
                    <Box key={`d${idx}`} style={{ color: "#CCC" }}>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12}>
                          <img src={pic} style={{ height: "80vh", objectFit: "contain" }} />
                        </Grid>
                        <Grid item xs={12}>
                          {props.edit && (
                            <Link underline="always" color="inherit" onClick={() => remove(idx)} style={{ cursor: "pointer", marginLeft: "1rem" }}>
                              remove
                            </Link>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })}
              </Carousel>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </Badge>
  );
}
