import React, { useEffect, useState, useContext } from "react";
import { Box, Button, Chip, Grid, Grow, Paper, Slide, Typography } from "@mui/material";
import Images from "../../common/component/image/Images";
import CloudinaryUploadWidget from "../../common/component/CloudinaryUploadWidget";
import Question from "../../common/component/Question";
import { useNavigate, useLocation } from "react-router-dom";
import SearchDesigns from "./SearchDesigns";
import TagSearchBox from "./TagSearchBox";
import { Link as RouterLink } from "react-router-dom";
import ConfigContext from "../../common/context/ConfigContext";
import Option from "../../common/component/Option";

const designOptions = [
  { title: "search", icon: "search.svg" },
  { title: "upload", icon: "upload.svg" }
];

const occasions = [
  { title: "birthday", icon: "birthday.svg" },
  { title: "baby shower", icon: "teddy.svg" },
  { title: "wedding", icon: "wedding.svg" },
  { title: "sweet sixteen", icon: "crown.svg" }
];

const birthdayOptions = [
  { title: "baby", icon: "infant.svg" },
  { title: "small kid", icon: "toddler.svg" },
  { title: "teen", icon: "bigkid.svg" },
  { title: "adult", icon: "adult.svg" }
];

const trending = ["burn-away", "heart", "letter", "number", "vintage"];

export default function Design(props) {
  const siteConfig = useContext(ConfigContext);

  const navigate = useNavigate();
  let location = useLocation();

  const [step, setStep] = useState("");

  const [occasion, setOccasion] = useState("");
  const [lifestage, setLifestage] = useState("");
  const [tags, setTags] = useState();

  const [pics, setPics] = useState([]);

  useEffect(() => {
    if (!location.hash || location.hash === "#design") {
      setStep("design");
    } else if (location.hash === "#occasion") {
      setStep("occasion");
    } else if (location.hash === "#lifestage") {
      setStep("lifestage");
      props.setProgress(20);
    } else if (location.hash.startsWith("#search")) {
      setStep("search");
      props.setProgress(35);
    } else if (location.hash === "#upload") {
      setStep("upload");
    }
  }, [location.hash]);

  const onSubmit = (keyword, photos) => {
    setPics(photos);
    props.onSubmit({ occasion, lifestage, pics: photos, keyword });
  };

  const handleLifestage = (value) => {
    setLifestage(value);
    navigate(`#search/${value.replace(/\s/g, "-")}`);
  };

  const handleOccasion = (value) => {
    setOccasion(value);
    if (value === "birthday") {
      navigate("#lifestage");
    } else {
      const token = value.replace(/\s/g, "-");
      navigate(`#search/${token}`);
    }
  };

  const handleDesign = (value) => {
    if (value === "search") {
      navigate(`#occasion`);
    } else if (value === "browse") {
      navigate(`/photo/collection/featured`);
    } else {
      navigate(`#upload`);
    }
  };

  return (
    <Box pb={20} textAlign="center">
      {step === "occasion" && (
        <Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={500}>
          <Box>
            <Question
              title="What are you celebrating?"
              subtitle="Search designs by occasion"
              options={occasions}
              otherOptions={["baptism", "job", "graduation", "anniversary"]}
              handleClick={handleOccasion}
            />
            <Box my={4}>
              <Box px={2} my={4} textAlign={"center"}>
                <Box mb={2}>
                  <Typography variant="subtitle1">Know what you're looking for?</Typography>
                </Box>
                <Grid container>
                  <Grid item sm={3} xs={12}></Grid>
                  <Grid item sm={6} xs={12}>
                    <Paper elevation={0}>
                      <TagSearchBox onSubmit={(k) => navigate(`#search/${k.replace(/\s/g, "-")}`)} />
                    </Paper>
                    <Box my={1}>
                      {trending &&
                        trending.map((o, idx) => {
                          return (
                            <Chip
                              key={idx}
                              sx={{ margin: "2px" }}
                              size="small"
                              color="secondary"
                              variant="outlined"
                              label={o}
                              clickable
                              component={RouterLink}
                              to={`#search/${o.replace(/\s/g, "-")}`}
                            />
                          );
                        })}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Slide>
      )}
      {step === "design" && (
        <Grow in={true} mountOnEnter unmountOnExit timeout={500}>
          <Box>
            <Paper>
              <Box px={2} py={7}>
                <Box mb={4}>
                  <Typography variant="h4" gutterBottom>
                    {"Let's pick a design"}
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    {"Search through 1000s of popular designs or upload your own design"}
                  </Typography>
                </Box>
                {siteConfig.id === "aqabe9A2spBl5fiN2iYb" ? (
                  <Option title="Browse" icon="search.svg" onClick={() => handleDesign("browse")} />
                ) : (
                  <Option title="Search" icon="search.svg" onClick={() => handleDesign("search")} />
                )}
                <CloudinaryUploadWidget folder={`s/${siteConfig.id}/item`} onComplete={(images) => onSubmit("", images)}>
                  <Option title="Upload" icon="upload.svg" />
                </CloudinaryUploadWidget>
              </Box>
            </Paper>
          </Box>
        </Grow>
      )}
      {step === "lifestage" && (
        <Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={500}>
          <Box>
            <Question title="Who's birthday is it?" options={birthdayOptions} handleClick={handleLifestage} />
          </Box>
        </Slide>
      )}
      {step === "upload" && (
        <Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={500}>
          <Paper>
            <Box px={2} py={7}>
              <Typography variant="h4" gutterBottom>
                Upload Inspiration Design
              </Typography>
              <Typography variant="body1" color="textSecondary" gutterBottom>
                Click below to add upto 5 photos
              </Typography>
              <Box py={3}>
                <Grid container>
                  <Grid item xs={3} sm={4}></Grid>
                  <Grid item xs={6} sm={4}>
                    <Images pics={pics} edit={true} onChange={(images) => setPics(images)} folder={`img/r`} limit={5} />
                    <Box my={2}>
                      <Button color="primary" variant="contained" onClick={() => onSubmit("", pics)} disabled={pics.length === 0} fullWidth>
                        Continue
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Paper>
        </Slide>
      )}
      {step === "search" && (
        <Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={500}>
          <Box>
            <SearchDesigns tags={tags} occasion={occasion} onSubmit={onSubmit} />
          </Box>
        </Slide>
      )}
    </Box>
  );
}
