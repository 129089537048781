import React, { useState, useEffect, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Backdrop, Box, Button, CircularProgress, Container, Grid, LinearProgress, Tab, Tabs, Typography } from "@mui/material";
import bkstApi from "../../common/api";
import Product from "../../common/component/Product";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import ConfigContext from "../../common/context/ConfigContext";
import ExpandableImage from "../../common/component/image/ExpandableImage";

export default function PhotoCollection(props) {
  const siteConfig = useContext(ConfigContext);

  const navigate = useNavigate();
  const { handle } = useParams();

  const [tag, setTag] = useState(handle || "featured");

  const [title, setTitle] = useState();
  const [description, setDescription] = useState();

  const [tags, setTags] = useState([]);

  const [products, setProducts] = useState([]);
  const [limit, setLimit] = useState("");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    bkstApi(`/site/${siteConfig.id}/photo/collection/${tag}`)
      .then((res) => {
        setTitle(res.data.title);
        setDescription(res.data.description);

        document.title = res.data.title;
        setProducts(res.data.products);
        if (res.data.limit) {
          setLimit(res.data.limit);
        }

        if (res.data.tags) {
          setTags(res.data.tags);
        }

        setLoading(false);

        window.scrollTo(0, 100);
      })
      .catch((error) => {
        console.error("Error:", error);
        navigate("/");
      });
  }, [handle]);

  const search = (newTag, newLimit) => {
    setLoading(true);
    if (newTag != tag) {
      window.scrollTo(0, 100);
    }

    bkstApi(`/site/${siteConfig.id}/photo/${newTag}?limit=${newLimit || ""}`)
      .then((res) => {
        setProducts(res.data.photos);
        if (res.data.limit) {
          setLimit(res.data.limit);
        }
        setTag(newTag);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        navigate("/");
      });
  };

  return (
    <Container maxWidth="lg">
      <Box pt={6}>
        <Typography variant="h1" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {description}
        </Typography>
      </Box>
      <Box pb={25}>
        <Box
          my={4}
          sx={{
            display: "flex",
            backgroundColor: "#ECECEC",
            position: "-webkit-sticky",
            position: "sticky",
            top: "0",
            border: "1px solid #EEE"
          }}
        >
          <Tabs value={tag} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
            <Tab label="Featured" value="featured" onClick={() => search("featured")} />
            {tags.map((cat, idx) => (
              <Tab key={idx} label={cat} value={cat} onClick={() => search(cat)} />
            ))}
          </Tabs>
        </Box>
        <Box my={4}>
          <Grid container spacing={3}>
            {products.map((value, index) => {
              return (
                <Grid item xs={6} sm={3} key={index} align="center" sx={{ display: limit == index ? "none" : "block" }}>
                  <Box>
                    <ExpandableImage
                      src={value.img}
                      style={{
                        width: "100%",
                        height: "100%",
                        aspectRatio: "1",
                        objectFit: "cover",
                        borderRadius: "4px",
                        border: "1px solid #ECECEC"
                      }}
                    >
                      <Button variant="contained" component={RouterLink} to="/get-quote/photo" state={{ req: { pics: [value.img] } }}>
                        GET QUOTE
                      </Button>
                    </ExpandableImage>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          {tag !== "featured" && products.length > +limit && (
            <Box my={4} textAlign={"center"}>
              <Button variant="contained" size="large" color="primary" onClick={() => search(tag, +limit * 2)}>
                Show More Photos...
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      {loading && (
        <Backdrop open={true} style={{ zIndex: "2000", color: "#fff" }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Container>
  );
}
