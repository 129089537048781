import React, { useContext, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import PhoneField from "./PhoneField";
import CloseIcon from "@mui/icons-material/Close";
import ConfigContext from "../context/ConfigContext";
import EmailInput from "./EmailInput";

export default function RequestToBook(props) {
  const siteConfig = useContext(ConfigContext);

  const [open, setOpen] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [consent, setConsent] = useState(false);

  const [errors, setErrors] = useState([]);

  const validate = () => {
    let arr = [];
    console.log("phone", phone);
    if (!phone) {
      arr.push("Phone is missing");
    } else if (phone.length < 10) {
      arr.push("Invalid Phone Number");
    }

    if (email.length < 5 || !email.includes("@") || !email.includes(".")) {
      arr.push("Invalid Email");
    }

    return arr;
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const arr = validate();
    if (arr.length > 0) {
      setErrors(arr);
    } else {
      props.onSubmit({ firstName, lastName, email, phone });
      setOpen(false);
    }
  };

  return (
    <span>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          const isValid = props.validate();
          if (isValid) {
            setOpen(true);
          }
        }}
        fullWidth
      >
        Request to Book
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth="xs">
        <form onSubmit={onSubmit}>
          <DialogTitle>
            Contact Info{" "}
            <IconButton
              aria-label="close"
              onClick={() => setOpen(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box>
              <Box mt={1} mb={3}>
                <Typography variant="body1" color="textSecondary">
                  Thanks for your interest. Please submit your contact info & we'll be in touch shortly.
                </Typography>
              </Box>
              <Grid container spacing={3} align="left">
                <Grid item sm={6} xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      value={firstName}
                      variant="outlined"
                      fullWidth
                      id="firstName"
                      label="First Name"
                      name="firstName"
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                    <FormHelperText></FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      value={lastName}
                      variant="outlined"
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                    <FormHelperText></FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <EmailInput value={email} onChange={(val) => setEmail(val)} required={true} />
                </Grid>
                <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <PhoneField name="Mobile" value={phone} onChange={(phone) => setPhone(phone)} required />
                  </FormControl>
                  <Box mt={1}>
                    <FormControlLabel
                      className="consent"
                      control={
                        <Checkbox required checked={consent} onChange={(e) => setConsent(e.target.checked)} name="consent" color="secondary" />
                      }
                      label={`I consent to receiving calls and SMS messages from ${siteConfig.biz}`}
                    />
                  </Box>
                </Grid>
                {errors.length > 0 && (
                  <Grid item xs={12}>
                    <Alert severity="error">{errors.join(". ")}</Alert>
                  </Grid>
                )}
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </span>
  );
}
