import { useState } from "react";
import { Box, Button, FormControl, FormHelperText, Grid, Paper, TextField, Typography } from "@mui/material";
import BDatePicker from "../../common/component/BDatePicker";
import PlacesAutocomplete from "../../common/component/PlacesAutocomplete";

export default function Event(props) {
  const [location, setLocation] = useState("");
  const [date, setDate] = useState("");
  const [budget, setBudget] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    props.onSubmit({ location, date, budget });
  };

  const onLocationChange = (value) => {
    setLocation(value);
    document.activeElement.blur();
  };

  return (
    <Paper>
      <Box px={2} py={6} textAlign="center">
        <Box>
          <Box mb={5}>
            <Typography variant="h4" gutterBottom>
              Tell us about your event
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              This helps us provide you with an accurate quote
            </Typography>
          </Box>
          <Box px={4}>
            <form onSubmit={onSubmit}>
              <Grid container spacing={3} align="left">
                <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <PlacesAutocomplete onChange={onLocationChange} value={location} required helperText="Where is your event?" />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <BDatePicker value={date || null} label="Event Date" onChange={(d) => setDate(d)} disablePast required />
                    <FormHelperText>When is your event?</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      value={budget}
                      variant="outlined"
                      fullWidth
                      type="number"
                      id="budget"
                      label="Budget"
                      name="budget"
                      onChange={(e) => setBudget(e.target.value)}
                      onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
                      helperText="Are you trying to stay within a specific budget?"
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Box my={4}>
                <Button size="large" color="primary" variant="contained" type="submit" fullWidth>
                  Continue
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}
