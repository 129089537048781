import React, { useState } from "react";
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function ExpandableImage(props) {
  const [open, setOpen] = useState(false);

  let style = Object.assign({ cursor: "pointer" }, props.style || { height: "40px", width: "40px", objectFit: "cover" });

  return (
    <span>
      <img alt="" src={props.src} style={style} onClick={() => setOpen(true)} />
      <Dialog fullScreen scroll="paper" open={open} onClose={() => setOpen(false)} onClick={() => setOpen(false)}>
        <DialogTitle style={{ backgroundColor: "#000", color: "#fff", margin: "0", border: 0 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            Photo
            <Box sx={{ display: "flex", justifyContent: "center", flexGrow: "1" }}>{props.children}</Box>
            <IconButton
              aria-label="close"
              onClick={() => setOpen(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "#FFF"
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent style={{ backgroundColor: "#000", color: "#fff", alignItems: "center" }}>
          <Box textAlign="center">
            <img alt="" src={props.src} style={{ maxWidth: "100%", maxHeight: "90vh" }} />
          </Box>
        </DialogContent>
      </Dialog>
    </span>
  );
}
export default ExpandableImage;
