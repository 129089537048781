import { useState, useContext } from "react";
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, Paper, TextField, Typography } from "@mui/material";
import PhoneField from "../../common/component/PhoneField";
import ConfigContext from "../../common/context/ConfigContext";
import EmailInput from "../../common/component/EmailInput";

export default function ContactDetails(props) {
  const siteConfig = useContext(ConfigContext);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [consent, setConsent] = useState(false);

  const [emailError, setEmailError] = useState("");

  const validateEmail = () => {
    if (email.length > 5 && email.includes("@") && email.includes(".")) {
      setEmailError("");
    } else {
      setEmailError("Invalid Email");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!emailError) {
      props.onSubmit({ name: `${firstName} ${lastName}`, firstName, lastName, email, phone });
    }
  };

  return (
    <Paper>
      <Box px={2} py={6} textAlign="center">
        <Box>
          <Box my={4}>
            <Typography variant="h4" gutterBottom>
              Final Step!
            </Typography>
            <Typography variant="body1" color="textSecondary">
              We will review your details & send you a quote within a few hours
            </Typography>
          </Box>
          <Box px={3}>
            <form onSubmit={onSubmit}>
              <Grid container spacing={3} align="left">
                <Grid item sm={6} xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      value={firstName}
                      variant="outlined"
                      fullWidth
                      id="firstName"
                      label="First Name"
                      name="firstName"
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                    <FormHelperText></FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      value={lastName}
                      variant="outlined"
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                    <FormHelperText></FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <EmailInput value={email} onChange={(val) => setEmail(val)} required={true} />
                </Grid>
                <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <PhoneField name="Mobile" value={phone} onChange={(phone) => setPhone(phone)} required />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    className="consent"
                    control={<Checkbox required checked={consent} onChange={(e) => setConsent(e.target.checked)} name="consent" color="secondary" />}
                    label={`I consent to receiving calls and SMS messages from ${siteConfig.biz}`}
                  />
                </Grid>
                <Grid item xs={12} align="right">
                  <Button color="primary" variant="contained" type="submit" fullWidth>
                    Get Quotes
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}
